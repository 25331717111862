<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ param.headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <template v-if="p01">
        <div class="box-ct">
          <v-form ref="form"
                  v-model="valid"
                  lazy-validation
          >
            <div class="table-form">
              <table>
                <caption class="hide">
                  사용자정보 항목
                </caption>
                <colgroup>
                  <col width="127px"/>
                  <col width="150px"/>
                  <col width="127px"/>
                  <col width=""/>
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">회사구분</v-badge>
                  </th>
                  <td colspan="3">
                    <div class="form-inp full">
                      <template>
                        <v-select
                            single-line
                            v-model="selectedCustKey"
                            :items="myAuth.USER_AUTH === '20190125141939798TWB52868'? aspAllCust : ASP_CUST_KEY"
                            item-text="CD_NM"
                            item-value="CD"
                            key="CD"
                            outlined
                            placeholder="선택하세요"
                            label="회사 선택"
                            :rules="validateRules.aspNewCustKey"
                            multiple
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon :color="selectedCustKey.length > 0 ? 'indigo darken-4' : ''">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 3" small>
                              <span>{{ item.CD_NM }}</span>
                              <template>
                                <v-btn
                                    icon
                                    x-small
                                    class="svg-close20 ml-1"
                                    v-show="dropItemRemove === true"
                                    @click.stop="removeDropitem(index)"
                                ></v-btn>
                              </template>
                            </v-chip>
                            <span
                                v-if="index === 3"
                                class="grey--text text-caption"
                            >
                    ...
                  </span>
                          </template>
                        </v-select>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용자ID</v-badge>
                  </th>
                  <td>
                    <v-text-field
                        class="form-inp full"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        @change="chkDupleUserID"
                        :rules="validateRules.userId"
                        v-model="USER_ID"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용자명</v-badge>
                  </th>
                  <td>
                    <v-text-field
                        class="form-inp full"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        :rules="validateRules.userNm"
                        v-model="USER_NM"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">닉네임</v-badge>
                  </th>
                  <td>
                    <v-text-field
                        class="form-inp full"
                        name="name"
                        value=""
                        id="id"
                        outlined
                        :rules="validateRules.userNick"
                        v-model="USER_NICK"
                    >
                    </v-text-field>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사용여부</v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-select
                          :items="dropYn"
                          item-text="title"
                          item-value="value"
                          outlined
                          placeholder="선택하세요"
                          v-model="USE_YN"
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">권한그룹명</v-badge>
                  </th>
                  <td colspan="3">
                    <div class="form-inp full">
                      <v-select
                          :items="dropGroupItems"
                          item-text="NM"
                          item-value="CD"
                          outlined
                          placeholder="선택하세요"
                          :rules="validateRules.atrtGroupCd"
                          :disabled="GIVE_AUTH"
                          v-model="ATRT_GROUP_CD"
                      ></v-select>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-form>
        </div>
      </template>
      <template v-if="p02">
        <div class="box-ct">
          <v-form ref="form"
                  v-model="valid"
                  lazy-validation
          >
            <div class="table-form">

          <table>
            <caption class="hide">
              사용자정보 항목
            </caption>
            <colgroup>
              <col width="127px"/>
              <col width="150px"/>
              <col width="127px"/>
              <col width=""/>
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">회사구분</v-badge>
              </th>
              <td colspan="3">
                <div class="form-inp full">
                  <template>
                    <v-select
                        single-line
                        v-model="selectedCustKey"
                        :items="myAuth.USER_AUTH === '20190125141939798TWB52868'? aspAllCust : ASP_CUST_KEY"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        placeholder="선택하세요"
                        label="회사 선택"
                        :rules="validateRules.aspNewCustKey"
                        multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                        >
                          <v-list-item-action>
                            <v-icon :color="selectedCustKey.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 3" small>
                          <span>{{ item.CD_NM }}</span>
                          <template>
                            <v-btn
                                icon
                                x-small
                                class="svg-close20 ml-1"
                                v-show="dropItemRemove === true"
                                @click.stop="removeDropitem(index)"
                            ></v-btn>
                          </template>
                        </v-chip>
                        <span
                            v-if="index === 3"
                            class="grey--text text-caption"
                        >
                    ...
                  </span>
                      </template>
                    </v-select>
                  </template>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용자ID</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_ID"
                    :rules="validateRules.userId"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">사용자명</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_NM"
                    :rules="validateRules.userNm"
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">닉네임</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_NICK"
                    :rules="validateRules.userNick"
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                      :items="dropYn"
                      item-text="title"
                      item-value="value"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      v-model="detailList.USE_YN"
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">권한그룹명</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                      :items="dropGroupItems"
                      item-text="NM"
                      item-value="CD"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      v-model="detailList.ATRT_GROUP_ID"
                      :rules="validateRules.atrtGroupCd"
                      :disabled="GIVE_AUTH"
                  ></v-select>
                </div>
              </td>
              <th scope="row">최근접속IP</th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.CNNCT_IP"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">등록자</th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.REGR_NM"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">등록일시</th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.VIEW_REG_DTTM"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">수정자</th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.AMDR_NM"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">수정일시</th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.VIEW_UPD_DTTM"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
          </v-form>
        </div>
      </template>
      <template v-if="p03">
        <div class="table-form">
          <table>
            <caption class="hide">
              사용자정보 항목
            </caption>
            <colgroup>
              <col width="127px"/>
              <col width="150px"/>
              <col width="127px"/>
              <col width=""/>
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">회사구분</v-badge>
              </th>
              <td colspan="3">
                <div class="form-inp full">
                  <template>
                    <v-container fluid>
                      <v-select
                          single-line
                          v-model="selectedCustKey"
                          :items="myAuth.USER_AUTH === '20190125141939798TWB52868'? aspAllCust : ASP_CUST_KEY"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          placeholder="선택하세요"
                          label="회사 선택"
                          :rules="validateRules.aspNewCustKey"
                          multiple
                          disabled
                      >
                        <template v-slot:prepend-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 3" small>
                            <span>{{ item.CD_NM }}</span>
                          </v-chip>
                          <span
                              v-if="index === 3"
                              class="grey--text text-caption"
                          >
                      ...
                    </span>
                        </template>
                      </v-select>
                    </v-container>
                  </template>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용자ID</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_ID"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">사용자명</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_NM"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">닉네임</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    hide-details=""
                    v-model="detailList.USER_NICK"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속</v-badge>
              </th>
              <td colspan="3">
                <div>
                  <div class="form-inp sm">
                    <v-select
                        :items="dropA"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        placeholder="선택하세요"
                        v-model="paramData.USER_ATTR_A"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                        :items="dropB"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        placeholder="선택하세요"
                        v-model="paramData.USER_ATTR_B"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <div class="form-inp sm">
                    <v-select
                        :items="dropC"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        placeholder="선택하세요"
                        v-model="paramData.USER_ATTR_C"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp sm ml-2">
                    <v-select
                        :items="dropD"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        placeholder="선택하세요"
                        v-model="paramData.USER_ATTR_D"
                    >
                    </v-select>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="p04">
        <div class="table-form">
          <table>
            <caption class="hide">
              사용자정보 항목
            </caption>
            <colgroup>
              <col width="127px"/>
              <col width="150px"/>
              <col width="127px"/>
              <col width=""/>
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속회사</v-badge>
              </th>
              <td colspan="3">
                <div class="form-inp full">
                  <v-select
                      :items="dropItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      placeholder="선택하세요"
                      v-model="paramData.ASP_NEWCUST_KEY"
                      disabled
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속그룹코드</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    v-model="paramData.ATTR_CD"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">소속코드</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    v-model="ATTR_DIV_CD"
                    :rules="validateRules.attrDivCd"
                    required
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속코드명</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    v-model="ATTR_DIV_NM"
                    :rules="validateRules.attrDivNm"
                    required
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                      :items="dropYn"
                      item-text="title"
                      item-value="value"
                      outlined
                      placeholder="선택하세요"
                      v-model="USE_YN"
                      :rules="validateRules.useYn"
                      required
                  >
                  </v-select>
                </div>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">정렬순서</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="SORT_ORD"
                    type="number"
                    id="sortOrd"
                    min="0"
                    max="9999"
                    v-model.number.lazy="SORT_ORD"
                    :rules="validateRules.sortOrd"
                    required
                ></v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="p05">
        <div class="table-form">
          <table>
            <caption class="hide">
              사용자정보 항목
            </caption>
            <colgroup>
              <col width="127px"/>
              <col width="150px"/>
              <col width="127px"/>
              <col width=""/>
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속회사</v-badge>
              </th>
              <td colspan="3">
                <div class="form-inp full">
                  <v-select
                      :items="dropItems"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      placeholder="선택하세요"
                      v-model="paramData.ASP_NEWCUST_KEY"
                      disabled
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속그룹코드</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    v-model="paramData.ATTR_CD"
                    disabled
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">소속코드</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="name"
                    outlined
                    v-model="paramData.ATTR_DIV_CD"
                    disabled
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">소속코드명</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                    class="form-inp full"
                    name="name"
                    value=""
                    id="id"
                    outlined
                    v-model="paramData.ATTR_DIV_NM"
                    :rules="validateRules.attrDivNm"
                    required
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사용여부</v-badge>
              </th>
              <td>
                <div class="form-inp full">
                  <v-select
                      :items="dropYn"
                      item-text="title"
                      item-value="value"
                      outlined
                      placeholder="선택하세요"
                      v-model="paramData.USE_YN"
                      :rules="validateRules.useYn"
                      required
                  >
                  </v-select>
                </div>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">정렬순서</v-badge>
              </th>
              <td>
                <v-text-field
                    class="form-inp full"
                    name="SORT_ORD"
                    type="number"
                    id="sortOrd"
                    min="0"
                    max="9999"
                    v-model.number.lazy="paramData.SORT_ORD"
                    :rules="validateRules.sortOrd"
                    required
                ></v-text-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <v-alert type="info" color="#F6B611" class="mt-2" v-if="footerYn">
        사용자 초기 비밀번호는 <strong>"사용자ID + 1!"</strong> 입니다.
      </v-alert>
    </div>
    <div class="text-right mt-3">
      <template v-if="p02">
        <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitPW')"
            outlined
            class="btn-point"
            @click="resetBtn"
            id="btnReset"
        >비밀번호 초기화
        </v-btn>
        <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnUnlock')"
            outlined
            class="btn-point ml-2"
            @click="unlockBtn"
            id="btnUnLock"
        >잠금해제
        </v-btn>
      </template>
      <v-btn
          v-if="this.mixin_set_btn(this.$options.name, 'btnSave')"
          outlined
          class="btn-point ml-2"
          @click="saveBtn"
          id="btnSave01"
      >저장
      </v-btn
      >
    </div>
  </div>
</div>
</template>

<script>

import api from "@/store/apiUtil";
import {mixin} from "../../mixin/mixin";
import gibberish from "../../mixin/gibberish-aes_mixin";

export default {
  name  : "MENU_M810401P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin, gibberish],
  props : {
    param      : {
      type: Object,
    },
    p01        : {
      type   : Boolean,
      default: false,
    },
    p02        : {
      type   : Boolean,
      default: false,
    },
    p03        : {
      type   : Boolean,
      default: false,
    },
    p04        : {
      type   : Boolean,
      default: false,
    },
    p05        : {
      type   : Boolean,
      default: false,
    },
    headerTitle: {
      type   : String,
      default: "제목",
    },
  },
  data() {
    return {
      aspAllCust    : [],
      ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company,
      dropItemRemove: true,
      removeDropitem(index) {
        this.selectedCustKey.splice(index, 1);
      },
      selectedCustKey: [],
      footerHideTitle: "닫기",
      dropItems      : [],
      dropYn         : [
        {title: "사용", value: "Y"},
        {title: "미사용", value: "N"},
      ],
      dropGroupItems : [],
      dropA          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropB          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropC          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropD          : [
        {CD: "", CD_NM: "선택"}
      ],
      detailList     : [],
      groupAddList   : [],

      //v-model
      ASP_NEWCUST_KEY: '',
      USER_ATTR_A    : '',
      USER_ATTR_B    : '',
      USER_ATTR_C    : '',
      USER_ATTR_D    : '',
      PROC_ID        : '', //처리자ID
      REGR_ID        : '', //등록자ID
      AMDR_ID        : '', //수정자ID
      USER_ID        : '',
      USER_NM        : '',
      USER_NICK      : '',
      USE_YN         : 'Y',
      ATRT_GROUP_CD  : '',
      ATTR_DIV_CD    : '',
      ATTR_DIV_NM    : '',
      SORT_ORD       : 0,
      footerYn       : false,

      myAuth: {},

      GIVE_AUTH:false,


      //메세지
      msgList: {
        chkCustLength: '회사명은 3개 이하로 선택해주세요',

      },
      //valid관련 데이터
      valid        : true,
      validateRules: {
        sortOrd : [
          v => /[0-9]/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
        aspNewCustKey: [
          v => !!v || '회사선택은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        userId       : [
          v => !!v || '사용자ID는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        userNick     : [
          v => !!v || '닉네임은 필수입니다.',
        ],
        userNm       : [
          v => !!v || '사용자명은 필수입니다.',
        ],
        atrtGroupCd  : [
          v => !!v || '권한그룹선택은 필수입니다.',
        ],
        useYn        : [
          v => !!v || '사용여부는 필수입니다.',
        ],
        attrDivNm    : [
          v => !!v || '소속코드명은 필수입니다.',
        ],
        attrDivCd    : [
          v => !!v || '소속코드는 필수입니다.',
        ],
      }
    }
  },

  methods: {

    //전체선택
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.selectedCustKey = []
        } else {
          this.selectedCustKey = this.aspAllCust.slice()
        }
      })
    },

    async init() {

      this.dropItems = this.$store.getters['userStore/GE_USER_COMPANY'];
      this.aspAllCust = await this.mixin_getAllAspData();
      this.PROC_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.REGR_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.AMDR_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.dropGroupItems = await this.mixin_user_auth_get();

      let itemLeg = this.dropGroupItems.length;
      let i = 0;

      api
        .post(
          "api/palette-main/myAuth/inqire", //api url입력
          {
            USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log("response.data.DATA", response.data.DATA);
          this.myAuth = response.data.DATA[0];
          let CHK_ITEMS = [];
          if(this.myAuth.USER_AUTH != '20190125141939798TWB52868'){
            for (i;i<itemLeg;i++){
              if(this.dropGroupItems[i].CD == '20190125141939798TWB52868'){
                delete this.dropGroupItems[i];
              }
            }
          }
          let n=0
          let dropGroupItems_LENG = this.dropGroupItems.length;
          for(n;n<dropGroupItems_LENG;n++){
            if(this.dropGroupItems[n] != '' && this.dropGroupItems[n] != undefined){
              CHK_ITEMS.push(this.dropGroupItems[n]);
            }
          }
          this.dropGroupItems = CHK_ITEMS;
        })
        .catch((err) => {
          alert(err);
        });
      

      let DATA_FLAG = this.param.type;


      if (DATA_FLAG == "U") {
        this.getDetailInfo();
        this.footerYn = true;
      } else if (DATA_FLAG == "I") {
        this.footerYn = true;
      } else if (DATA_FLAG == "GU") {
        this.getDetailInfo();
      }
    },

    //암호화
    common_aesEncrypt(strPassWD, strEncryptKey) {
      return gibberish.aesEncrypt(strPassWD, strEncryptKey);
    },

    //validation체크
    validate() {
      return this.$refs.form.validate();
    },

    async getDetailInfo() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/detail";
      requestData.headers["METHOD"] = "detail";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEYS;
      requestData.sendData["USER_ID"] = this.paramData.USER_ID;

      this.result(requestData);
    },
    getDetailInfoCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        let header = response.HEADER;
        let data = response.DATA;
        if(!this.mixin_isEmpty(data) && data.length > 0 ){
          this.detailList = data[0];
        }
        if (this.detailList) {
          this.selectedCustKey = this.detailList.ASP_NEWCUST_KEY_DE.split(',');
        }

        //소속 셀렉트 박스 설정
        for (let i in this.paramData.dropA) {
          this.dropA.push(this.paramData.dropA[i]);
        }
        for (let i in this.paramData.dropB) {
          this.dropB.push(this.paramData.dropB[i]);
        }
        for (let i in this.paramData.dropC) {
          this.dropC.push(this.paramData.dropC[i]);
        }
        for (let i in this.paramData.dropD) {
          this.dropD.push(this.paramData.dropD[i]);
        }
      }else{
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },

    //저장버튼 분기
    saveBtn() {
      let DATA_FLAG = this.param.type;
      if (DATA_FLAG == "U" || DATA_FLAG == "I") { //상세및 업데이트
        this.passwordProcess(DATA_FLAG);
      } else if (DATA_FLAG == "GU") { //소속등록
        this.groupAdd();
      } else if (DATA_FLAG == "ATTR_U") {
        this.attrGroupUpdate();
      } else if (DATA_FLAG == "ATTR_I") {
        this.dupleKeyChk();
      }

    },
    //소속등록
    async groupAdd() {

      if (this.paramData.USER_ATTR_A == '' || this.paramData.USER_ATTR_B == '') {
        let msg = "소속A와 소속B는 필수 입력입니다."
        this.common_alert(msg, 'error');

        return
      }

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/registAttr";
      requestData.headers["METHOD"] = "registAttr";

      console.log(this.paramData);

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_ATTR"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USER_ATTR_A"] = this.paramData.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.paramData.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.paramData.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.paramData.USER_ATTR_D;
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자명
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자명
      requestData.sendData["REGR_DTTM"] = "SYSDATE";
      requestData.sendData["UPD_DTTM"] = "SYSDATE";

      this.result(requestData);

    },
    groupAddCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA;

      this.groupAddList = data[0];

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : "정상처리 되었습니다.",
        iconClass        : "svg-done-lg",
        type             : "default",
      });

      this.$emit('submit');
    },

    getDateFormat(value) {
      if (value) {
        let dateObj = value.split('/');
        let timeObj = value.split(':');
        let resultDate = dateObj[0] + dateObj[1] + dateObj[2].substr(0, 2) + timeObj[0].substr(0, -2) + timeObj[1]
        return resultDate;
      } else {
        return value;
      }

    },

    async passwordProcess(DATA_FLAG) {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "login/password-encpt";
      requestData.headers["URL"] = "/api/login/password-encpt/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;
      requestData.headers["passwordProcess"] = 'passwordProcess';

      this.result(requestData);
    },
    passwordProcessCallBack(response) {
      let header = response.HEADER;
      let DATA_FLAG = header.DATA_FLAG;
      let strEncryptKey = response.DATA[0].ENCRYPT_KEY;
      let strPassWD = '';
      if (strEncryptKey === "") {
        return;
      }

      //생성된 암호화 키 정보를 사용하여 ID와 password필드값을 AES 256byte 알고리즘을 이용하여 암호화한다.
      if (DATA_FLAG == "I") {
        strPassWD = this.USER_ID;
      } else {
        strPassWD = this.detailList.USER_ID;
      }
      let PWD = this.common_aesEncrypt(strPassWD, strEncryptKey);


      if (DATA_FLAG == "U") {
        this.updateDetail(PWD, DATA_FLAG);
      } else if (DATA_FLAG == "I") {
        this.userAdd(PWD, DATA_FLAG, strEncryptKey);
      } else if (DATA_FLAG == "reset") {
        this.resetPWD(strEncryptKey, PWD, DATA_FLAG);
      }

    },

    //소속 중복 체크
    async dupleKeyChk() {
      console.log(this.ATTR_DIV_CD);
      if (this.ATTR_DIV_CD.length < 2) {
        let msg = '소속코드를 2자 이상으로 입력해 주세요.';

        this.common_alert(msg, 'error');
        return
      }

      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/dplct-ceck/inqire";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage.dplct-ceck";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_CUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_DIV_CD"] = this.ATTR_DIV_CD;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;

      let response = await this.common_postCall(requestData);
      this.dupleKeyChkCallback(response);
    },
    dupleKeyChkCallback(response) {
      if (Object.keys(response.DATA).length > 0) {
        let msg = '이미 사용중인 소속 코드입니다.';

        this.common_alert(msg, 'error');
        return
      }
      this.attrGroupAdd();
    },

    //소속 등록
    async attrGroupAdd() {
      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/regist";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;
      requestData.sendData["ATTR_DIV_CD"] = this.ATTR_DIV_CD;
      requestData.sendData["ATTR_DIV_NM"] = this.ATTR_DIV_NM;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["SORT_ORD"] = this.SORT_ORD;
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자ID
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자ID

      let attrGroupAddResponse = await this.common_postCall(requestData);
      console.log("attrGroupAddResponse");
      console.log(attrGroupAddResponse);
      this.attrGroupAddCallback(attrGroupAddResponse);
    },
    attrGroupAddCallback(response) {
      if (response.HEADER.ERROR_FLAG == false) {
        this.common_alert(response.HEADER.ERROR_MSG, "done");
        this.$emit('submit');
      }
    },

    //소속 업데이트
    attrGroupUpdate() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage";


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;
      requestData.sendData["ATTR_DIV_CD"] = this.paramData.ATTR_DIV_CD;
      requestData.sendData["ATTR_DIV_NM"] = this.paramData.ATTR_DIV_NM;
      requestData.sendData["USE_YN"] = this.paramData.USE_YN;
      requestData.sendData["SORT_ORD"] = this.paramData.SORT_ORD;
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자ID

      this.result(requestData);

    },
    attrGroupUpdateCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      if (header.ERROR_FLAG == false) {
        //alert창 설정
        let iconType = 'done';
        let msg = header.ERROR_MSG

        this.common_alert(msg, iconType);

        this.$emit('submit');
      } else {
        let iconType = 'error';
        let msg = header.ERROR_MSG

        //alert창 띄우기
        this.common_alert(msg, iconType);
      }

    },

    //비밀번호 초기화 버튼
    resetBtn() {
      this.passwordProcess("reset");
    },

    unlockBtn() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "setting.agent.manage.password-lock-initl";
      requestData.headers["URL"] = "/api/setting/agent/manage/password-lock-initl/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = "unlock";

      //sendData 세팅

      requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.detailList.ASP_NEWCUST_KEY_DE;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = this.detailList.HP_NO;
      requestData.sendData["PROC_ID"] = this.detailList.PROC_ID; //최종수정자
      requestData.sendData["BIZ_DIV"] = 'RESETPWD';
      requestData.sendData["PWD_STATUS"] = 'NORMAL';
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;


      this.result(requestData);
    },
    unlockBtnCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      //alert창 설정
      let iconType = 'done';
      let msg = "잠금 해제 되었습니다.";

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('unlock');
    },

    resetPWD(strEncryptKey, PWD, DATA_FLAG) {

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "setting.agent.manage.password-initl";
      requestData.headers["URL"] = "/api/setting/agent/manage/password-initl/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;

      //sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.detailList.ASP_NEWCUST_KEY_DE;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = this.detailList.HP_NO;
      requestData.sendData["PROC_ID"] = this.detailList.PROC_ID; //최종수정자
      requestData.sendData["NEW_PWD"] = PWD;
      requestData.sendData["ENCRYPT_KEY"] = strEncryptKey;
      requestData.sendData["BIZ_DIV"] = 'RESETPWD';
      requestData.sendData["PWD_STATUS"] = 'RESET';
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;

      this.result(requestData);

    },
    resetPWDCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      let iconType = 'done';
      let msg = "비밀번호가 변경 되었습니다.";

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('reset');
    },

    chkDupleUserID() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/id-dpict-ceck/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = "setting.agent.manage.id-dpict-ceck";

      // sendData 세팅
      requestData.sendData["USER_ID"] = this.USER_ID;

      this.result(requestData);

    },
    chkDupleUserIDCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];
      let msg = "사용할 수 없습니다! \n" + this.USER_ID + " 은(는) 이미 사용중"

      if (data) {
        this.common_alert(msg, "error");
        this.valid = false;
      } else {
        this.valid = true;
      }


    },

    userAdd(PWD, DATA_FLAG, strEncryptKey) {
      if (!this.validate()) {
        return;
      }

      if (this.selectedCustKey.length > 3) {
        let msg = "회사명은 3개 이하로 선택해주세요"
        this.common_alert(msg, "error");

        return false
      }


      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["ASP_NEWCUST_KEY_RE"] = this.selectedCustKey.toString();
      requestData.sendData["PWD"] = PWD;
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_NICK"] = this.USER_NICK;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["ATRT_GROUP_CD"] = this.ATRT_GROUP_CD;
      requestData.sendData["PROC_ID"] = this.PROC_ID; //처리자
      requestData.sendData["REGR_DEPT_CD"] = "x";
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자
      requestData.sendData["REGR_DTTM"] = "SYSDATE";
      requestData.sendData["ENCRYPT_KEY"] = strEncryptKey;

      this.result(requestData);
    },
    userAddCallBack(response) {
      let iconType = 'done';
      let msg = "정상처리 되었습니다.";

      if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        msg = response.HEADER.ERROR_MSG;
        iconType = 'error';
      }

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('submit');

    },

    updateDetail(PWD, DATA_FLAG) {
      if (!this.validate()) {
        return;
      }

      if (this.selectedCustKey.length > 3) {
        let msg = "회사명은 3개 이하로 선택해주세요"
        this.common_alert(msg, "error");

        return false
      }

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.selectedCustKey.toString();
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = '';
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["PROC_ID"] = this.PROC_ID; //최종수정자
      requestData.sendData["ASP_CUST_KEY"] = this.paramData.ASP_NEWCUST_KEY;
      requestData.sendData["PWD"] = PWD;
      requestData.sendData["AMDR_DEPT_CD"] = "x";
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자명
      requestData.sendData["UPD_DTTM"] = "SYSDATE";


      this.result(requestData);

    },
    updateDetailCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : "정상처리 되었습니다.",
        iconClass        : "svg-done-lg",
        type             : "default",
      });
        this.$emit('submit');
      }else {this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')};
    },


    async result(requestData) {
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {
            head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            // string으로 넘어올 경우 에러임.
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg              : responseData.HEADER.ERROR_MSG,
                iconClass        : "svg-error-lg",
                type             : "default",
              });
              return;
            }

            if (response.data.HEADER.ERROR_FLAG) {
              let msg = response.data.HEADER.ERROR_MSG
              this.common_alert(msg, "error");
              return;
            }


            if (response.data.HEADER.METHOD == "detail") {
              this.getDetailInfoCallBack(response.data);
            } else if (response.data.HEADER.passwordProcess == "passwordProcess") {
              this.passwordProcessCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "reset") {
              this.resetPWDCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "regist" && response.data.HEADER.DATA_FLAG == "U") {
              this.updateDetailCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "regist" && response.data.HEADER.DATA_FLAG == "I") {
              this.userAddCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "registAttr") {
              this.groupAddCallBack(response.data);
            } else if (response.data.HEADER.SERVICE == "setting.agent.manage.id-dpict-ceck") {
              this.chkDupleUserIDCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "unlock") {
              this.unlockBtnCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "modify") {
              this.attrGroupUpdateCallBack(response.data);
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

      //결과값 보여주기
    },
  },
  mounted() {
    this.init();
    console.log(this.paramData)
    api
        .post(
          "api/palette-main/myAuth/inqire", //api url입력
          {
            USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log("response.data.DATA", response.data.DATA);
          let USER_AUTH = response.data.DATA[0];
          console.log("USER_AUTH", USER_AUTH);
          if(this.myAuth == '20190125141939798TWB52868'){
            this.GIVE_AUTH = false;
          } else if(USER_AUTH == '20190125141939798TWB52868' && this.myAuth != '20190125141939798TWB52868'){
            this.GIVE_AUTH = true;
          }
          console.log("this.GIVE_AUTH", this.GIVE_AUTH);
        })
        .catch((err) => {
          alert(err);
        });
  },
  computed: {
    initHeaders() {
      return {
        SERVICE: 'setting.agent.manage',
        METHOD : "",
        TYPE   : 'BIZ_SERVICE',
      };
    },
    selectAll() {
      return this.selectedCustKey.length === this.aspAllCust.length
    },
    icon() {
      if (this.selectAll) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },


    paramData() {
      return {
        USER_ID         : this.param.data.USER_ID,
        ASP_NEWCUST_KEY : this.param.data.ASP_NEWCUST_KEY,
        ASP_NEWCUST_KEYS: this.param.data.ASP_NEWCUST_KEYS,
        LAST_LOGIN_DT   : this.param.data.LAST_LOGIN_DT,
        USER_ATTR_A     : this.param.data.USER_ATTR_A,
        USER_ATTR_B     : this.param.data.USER_ATTR_B,
        USER_ATTR_C     : this.param.data.USER_ATTR_C,
        USER_ATTR_D     : this.param.data.USER_ATTR_D,
        dropA           : this.param.dropA,
        dropB           : this.param.dropB,
        dropC           : this.param.dropC,
        dropD           : this.param.dropD,
        ATTR_CD         : this.param.data.ATTR_CD,
        ATTR_DIV_CD     : this.param.data.ATTR_DIV_CD,
        ATTR_DIV_NM     : this.param.data.ATTR_DIV_NM,
        USE_YN          : this.param.data.USE_YN,
        SORT_ORD        : this.param.data.SORT_ORD,
      };
    },

  }
};
</script>

<style></style>
